// Auth
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

// Messages
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

// Likes
export const SET_LIKES = 'SET_LIKES';
export const SET_LIKE = 'SET_LIKE';
export const REMOVE_LIKE = 'REMOVE_LIKE';

// Shows
export const SET_SHOWS = 'SET_SHOWS';
export const SET_EPISODE = 'SET_EPISODE';
export const SET_SERIE = 'SET_SERIE';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_CATEGORIES = 'SET_CATEGORIES';

