import { combineReducers } from 'redux';
import auth from './auth';
import message from './message';
import shows from './shows';
import likes from './likes';

export default combineReducers({
    auth,
    message,
    shows,
    likes
});

