import * as actions from 'actions/types';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null };

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case actions.REGISTER_SUCCESS:
            return {
                ...state,
                isLoggedIn: false
            };
        case actions.REGISTER_FAIL:
            return {
                ...state,
                isLoggedIn: false
            };
        case actions.LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user
            };
        case actions.LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            };
        case actions.LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            };
        default:
            return state;
    }
}

