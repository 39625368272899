import axios from 'axios';

const API_URL = process.env.API_URL;

const getShows = async () => {
    const shows = await axios.get(`${API_URL}/shows`);
    return shows.data;
};

const getEpisode = id => {
    return axios.get(`${API_URL}/video/${id}`).then(response => response.data);
};

const getSerie = id => {
    return axios.get(`${API_URL}/serie/${id}`).then(response => response.data);
};

const getCategories = id => {
    return axios.get(`${API_URL}/category/${id}`).then(response => response.data);
};

export default {
    getShows,
    getEpisode,
    getSerie,
    getCategories
};

