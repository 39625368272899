import * as actions from 'actions/types';

const initialState = {
    shows: null,
    categories: null,
    serie: null,
    current: null
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case actions.SET_SHOWS:
            return {
                ...state,
                shows: payload
            };
        case actions.SET_EPISODE:
            return {
                ...state,
                current: payload
            };
        case actions.SET_SERIE:
            return {
                ...state,
                serie: payload
            };
        case actions.SET_CATEGORIES:
            return {
                ...state,
                categories: payload
            };
        default:
            return state;
    }
}

