import { SET_LIKES, SET_LIKE, REMOVE_LIKE } from 'actions/types';

const initialState = null;

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_LIKES:
            return payload;
        case SET_LIKE:
            return [...state, payload.like];
        case REMOVE_LIKE:
            return state.reduce((acc, like) => (like.id === payload ? acc : [...acc, like]), []);
        default:
            return state;
    }
}

