import React, { Suspense, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import ReactPixel from 'react-facebook-pixel';

const Menu = React.lazy(() => import('components/menu/Menu'));
const Footer = React.lazy(() => import('components/footer/Footer'));
const Home = React.lazy(() => import('components/home/Home'));
const Login = React.lazy(() => import('./Login'));
const Register = React.lazy(() => import('./Register'));
const Profile = React.lazy(() => import('./Profile'));
const Episode = React.lazy(() => import('components/series/Episode'));
const ForgotPassword = React.lazy(() => import('components/ForgotPassword'));
const ResetPassword = React.lazy(() => import('components/ResetPassword'));
const Category = React.lazy(() => import('components/Category'));
const Seasons = React.lazy(() => import('components/Seasons'));
const Terminos = React.lazy(() => import('components/Terminos'));
const Search = React.lazy(() => import('components/Search'));

import { getShows } from 'actions/shows';
import { clearMessage } from 'actions/message';

import { history } from 'helpers/history';

const App = () => {
    const [windowLocation, setWindowLocation] = useState(window.location.href);
    const dispatch = useDispatch();
    const shows = useSelector(state => state.shows.shows);

    useEffect(() => {
        const analytics = Analytics({
            app: 'syngenta-play',
            version: 1.0,
            plugins: [
                googleTagManager({
                    containerId: 'GTM-MMLKZK5'
                })
            ]
        });
        analytics.page();

        const fbOptions = {
            autoConfig: true,
            debug: false
        };
        ReactPixel.init('654220095269573', {}, fbOptions);
        ReactPixel.pageView();

        history.listen(location => {
            setWindowLocation(location);
            window.scrollTo(0, 0);
            const loc = window.location.href;

            analytics.page({ url: loc });
            ReactPixel.pageView();

            //window.gtag('config', 'G-BNJQ1Q12DC', {
            //    page_title: loc,
            //    page_path: loc
            //});

            dispatch(clearMessage());
        });
    }, [dispatch, windowLocation]);

    useEffect(() => {
        if (!shows) {
            dispatch(getShows());
        }
    }, [dispatch, shows]);

    return (
        <Router history={history}>
            <Suspense fallback={<></>}>
                <div className="main">
                    <Menu location={windowLocation} />
                    <div className="mainContent -flex -flex-direction-column -flex-justify-between">
                        <Switch>
                            <Route exact path={['/', '/home']} component={Home} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/registro" component={Register} />
                            <Route exact path="/perfil" component={Profile} />
                            <Route exact path="/forgot" component={ForgotPassword} />
                            <Route exact path="/terminos" component={Terminos} />
                            <Route path="/reset/:token" component={ResetPassword} />
                            <Route path="/category/:category/" component={Category} />
                            <Route path="/:category/:show/temporadas" component={Seasons} />
                            <Route
                                path="/academia/demoday/:ep"
                                render={() => <Episode show="demoday" cat="academia" />}
                            />
                            <Route
                                path="/academia/diaexperto/:ep"
                                render={() => <Episode show="diaexperto" cat="academia" />}
                            />
                            <Route
                                path="/academia/campomesa/:ep"
                                render={() => <Episode show="campomesa" cat="academia" />}
                            />
                            <Route
                                path="/academia/vozagricultor/:ep"
                                render={() => <Episode show="vozagricultor" cat="academia" />}
                            />
                            <Route
                                path="/academia/unboxsyng/:ep"
                                render={() => <Episode show="unboxsyng" cat="academia" />}
                            />
                            <Route
                                path="/presenta/tierra-unica/:ep"
                                render={() => <Episode show="tierra-unica" cat="presenta" />}
                            />
                            <Route
                                path="/presenta/ruta-gusano/:ep"
                                render={() => <Episode show="ruta_gusano" cat="presenta" />}
                            />
                            <Route
                                path="/presenta/historiascampo/:ep"
                                render={() => <Episode show="historiascampo" cat="presenta" />}
                            />
                            <Route
                                path="/reporte/cultivomira/:ep"
                                render={() => <Episode show="cultivomira" cat="reporte" />}
                            />
                            <Route
                                path="/futuro/sembrando-futuro/:ep"
                                render={() => <Episode show="sembrando-futuro" cat="futuro" />}
                            />
                            <Route
                                path="/futuro/somoscampo/:ep"
                                render={() => <Episode show="somoscampo" cat="futuro" />}
                            />
                            <Route path="/search" render={() => <Search />} />
                            <Route path="*">
                                <Redirect to="/" />
                            </Route>
                        </Switch>
                    </div>
                    <Footer />
                </div>
            </Suspense>
        </Router>
    );
};

export default App;

