import { SET_EPISODE, SET_SERIE, SET_CATEGORIES, SET_SHOWS } from './types';

import ShowsService from 'services/shows.service';

export const getShows = () => {
    return async dispatch => {
        const shows = await ShowsService.getShows();
        dispatch({
            type: SET_SHOWS,
            payload: shows
        });
    };
};

export const getSerie = id => {
    return async dispatch => {
        const serie = await ShowsService.getSerie(id);
        dispatch({
            type: SET_SERIE,
            payload: serie
        });
    };
};

export const getEpisode = id => {
    return async dispatch => {
        const episode = await ShowsService.getEpisode(id);
        dispatch({
            type: SET_EPISODE,
            payload: episode
        });
    };
};

export const getCategories = () => {
    return async dispatch => {
        const categories = await ShowsService.getCategories();
        dispatch({
            type: SET_CATEGORIES,
            payload: categories
        });
    };
};

